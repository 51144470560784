<template>
    <LiefengContent>
        <template v-slot:title>{{ `用餐评价` }}</template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <Button type="info" @click="analysisStatusFn(true)">标签设置</Button>
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                :pagesizeOpts="[20, 30, 50, 100]"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>

            <LiefengModal title="标签设置" :fullscreen="false" :value="analysisStatus" @input="analysisStatusFn" class="analysis">
                <template v-slot:contentarea>
                    <div>
                        <Button type="primary" @click="addBtn">新增标签</Button>
                    </div>
                    <LiefengTable :talbeColumns="talbeColumns2" :tableData="tableData2" :fixTable="true" :hidePage="true"></LiefengTable>
                </template>
                <template v-slot:toolsbar>
                    <Button @click="analysisStatusFn(false)" type="info" style="margin-right: 10px">关闭</Button>
                </template>
            </LiefengModal>

            <LiefengModal :title="type == 'add' ? '新增标签' : '修改标签'" :fullscreen="false" :value="addStatus" @input="addStatusFn" class="analysis">
                <template v-slot:contentarea>
                    <Form :label-width="120">
                        <FormItem>
                            <span slot="label" class="validate">标签名称:</span>
                            <Input v-model.trim="addPost.labelName"></Input>
                        </FormItem>
                    </Form>
                </template>
                <template v-slot:toolsbar>
                    <Button @click="addStatusFn(false)" type="info" style="margin-right: 10px">关闭</Button>
                    <Button @click="saveOrgLabel(false)" type="primary" style="margin-right: 10px">保存</Button>
                </template>
            </LiefengModal>

            <Preview ref="preview"></Preview>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import Preview from "../comment/components/Preview.vue"

export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        Preview,
    },
    data() {
        return {
            talbeColumns: [
                {
                    title: "商家机构名称",
                    align: "center",
                    minWidth: 100,
                    key: "username",
                },
                {
                    title: "机构编码",
                    align: "center",
                    minWidth: 100,
                    key: "orgCode",
                },
                {
                    title: "评分",
                    align: "center",
                    minWidth: 100,
                    key: "mobile",
                },
                {
                    title: "评价内容",
                    align: "center",
                    minWidth: 100,
                    key: "content",
                },
                {
                    title: "标签",
                    align: "center",
                    minWidth: 100,
                    key: "labelType",
                    render: (h, params) => {
                        return h(
                            "div",
                            {},
                            params.row.labelType && params.row.labelType != ""
                                ? (() => {
                                      var name = ""
                                      this.tableData2.map(item => {
                                          if (params.row.labelType == item.labelType) {
                                              name = item.labelName
                                          }
                                      })
                                      return name
                                  })()
                                : ""
                        )
                    },
                },
                {
                    title: "图片",
                    align: "center",
                    minWidth: 100,
                    key: "content",
                    render: (h, params) => {
                        const files = []

                        const images = params.row.images?.split(",")

                        images?.forEach(item => {
                            if (!item) return
                            files.push(
                                h("div", {
                                    style: {
                                        width: "50px",
                                        height: "50px",
                                        margin: "3px",
                                        background: `url(${item})`,
                                        "background-size": "cover",
                                        "background-position": "center",
                                        "border-radius": "5px",
                                        cursor: "pointer",
                                    },
                                    on: {
                                        click: () => {
                                            this.$refs.preview.display(item, images)
                                        },
                                    },
                                })
                            )
                        })

                        return h(
                            "div",
                            {
                                style: {
                                    margin: "10px 0",
                                },
                            },
                            [
                                ...(files.length > 0
                                    ? [
                                          h(
                                              "div",
                                              {
                                                  style: {
                                                      display: "flex",
                                                      "justify-content": "center",
                                                      "flex-wrap": "wrap",
                                                      "margin-top": "5px",
                                                  },
                                              },
                                              files
                                          ),
                                      ]
                                    : []),
                            ]
                        )
                    },
                },
                {
                    title: "视频",
                    align: "center",
                    minWidth: 100,
                    key: "content",
                    render: (h, params) => {
                        const files = []

                        if (params.row.videos) {
                            files.push(
                                h("video", {
                                    attrs: {
                                        src: params.row.videos,
                                        controls: true,
                                    },
                                    style: {
                                        width: "150px",
                                        height: "100px",
                                        margin: "3px",
                                        "border-radius": "5px",
                                    },
                                })
                            )
                        }

                        return h(
                            "div",
                            {
                                style: {
                                    margin: "10px 0",
                                },
                            },
                            [
                                ...(files.length > 0
                                    ? [
                                          h(
                                              "div",
                                              {
                                                  style: {
                                                      display: "flex",
                                                      "justify-content": "center",
                                                      "flex-wrap": "wrap",
                                                      "margin-top": "5px",
                                                  },
                                              },
                                              files
                                          ),
                                      ]
                                    : []),
                            ]
                        )
                    },
                },
                {
                    title: "评价账号",
                    align: "center",
                    minWidth: 100,
                    key: "gmtModified",
                },
                {
                    title: "操作时间",
                    align: "center",
                    minWidth: 100,
                    key: "gmtCreate",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
            ],
            talbeColumns2: [
                {
                    title: "标签名称",
                    align: "center",
                    minWidth: 100,
                    key: "labelName",
                },
                {
                    fixed: "right",
                    title: "操作",
                    minWidth: 120,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginLeft: "14px",
                                    },
                                    on: {
                                        click: () => {
                                            this.type = "edit"
                                            this.addStatusFn(true)
                                            this.addPost = {
                                                orgLabelId: params.row.orgLabelId,
                                                labelType: params.row.labelType,
                                                labelName: params.row.labelName,
                                                orgCode: params.row.orgCode,
                                            }
                                        },
                                    },
                                },
                                "修改"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginLeft: "14px",
                                    },
                                    on: {
                                        click: () => {
                                            this.delete(params.row.orgLabelId)
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            tableData2: [],
            tableData: [],
            page: 1,
            pageSize: 20,
            loading: false,
            total: 0,
            btnLoading: false,
            search: {
                termainlId: "",
                orgCode: "",
            },
            addPost: {
                orgLabelId: null,
                labelType: "1",
                labelName: "",
                orgCode: "",
            },

            analysisStatus: false,
            addStatus: false,
            type: "add",
        }
    },
    async created() {
        await this.getList()
        await this.selectLabelByOrgCode()
    },
    methods: {
        addBtn() {
            this.type = "add"
            this.addStatusFn(true)
        },
        delete(orgLabelId) {
            this.$get("/gateway/api/sysmartscreen/app/orgLabel/deleteLabel", {
                orgLabelId,
            }).then(res => {
                if (res && res.code == 200) {
                    this.$Message.success({
                        content: "删除成功",
                        background: true,
                    })
                    this.selectLabelByOrgCode()
                } else {
                    this.$Message.error({
                        content: "删除失败",
                        background: true,
                    })
                    return
                }
            })
        },
        addStatusFn(status) {
            this.addStatus = status
            if (status && this.type == "add") {
                this.addPost = {
                    orgLabelId: null,
                    labelType: "1",
                    labelName: "",
                    orgCode: "",
                }
            }
        },
        analysisStatusFn(status) {
            this.analysisStatus = status
        },
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        async getList() {
            this.loading = true
            await this.$get("/gateway/api/sysmartscreen/app/orgLabel/selectOrgAdvicePage", {
                orgCode: this.search.orgCode || "",
                termainlId: this.search.termainlId || "",
                page: this.page,
                pageSize: this.pageSize,
            }).then(res => {
                this.loading = false
                this.tableData = res.dataList
            })
        },
        async selectLabelByOrgCode() {
            await this.$get("/gateway/api/sysmartscreen/app/orgLabel/selectLabelByOrgCode", {
                orgCode: "",
            }).then(res => {
                this.tableData2 = res.dataList
            })
        },
        saveOrgLabel() {
            if (!this.addPost.labelName || this.addPost.labelName == "") {
                this.$Message.warning({
                    content: "标签名称不能为空",
                    background: true,
                })
                return
            }
            this.$post(
                "/gateway/api/sysmartscreen/app/orgLabel/saveOrgLabel",
                {
                    ...this.addPost,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res && res.code == 200) {
                    this.$Message.success({
                        content: "操作成功",
                        background: true,
                    })
                    this.selectLabelByOrgCode()
                    this.addStatusFn(false)
                    return
                } else {
                    this.$Message.error({
                        content: "操作失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
</style>
